import {
  POLL_USER_ANSWER_VERSION_BASE_FRAGMENT,
} from '@/graphql/_Fragments/PollUserAnswerVersion/Base';

export const POLL_BASE_FRAGMENT = `
  fragment pollBaseFragment on Poll {
    uid
    title
    realTimeAnswers
    startTime
    endTime
    startTimestamp
    endTimestamp
    versions(orderBy: createdAt_desc first: 1) {
      ...pollUserAnswerVersionBaseFragment
    }
    session {
      uid
      parentSession {
         uid
      }
    }
  }
  ${POLL_USER_ANSWER_VERSION_BASE_FRAGMENT}
`;
